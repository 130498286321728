import axios from "./../axios_request";
import { PAPI } from "../../libs/_endpoint";
import { EMAIL, MEMBER } from "../action_const";
import { getCookie, removeCookie, setCookie } from "../../libs/cookie";
import * as APIS from "../../libs/apis";
import { setNavigatorCredential } from "./action_login";
import { ERROR } from "../../libs/utils";

const cooldown_time = 60;

export const OTPEmailRequest = (email, lang) => {
  return (dispatch) => {
    dispatch({
      type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
      data: Object.assign({ code: false }, { email }),
    });

    let _time = window.ps.getNowDate().getTime();
    cooldown(dispatch, _time); //set cooldown

    axios({
      method: "post",
      url: `${PAPI}/oauth2/OTPEmailRequest`,
      data: { email, lang },
    })
      .then(({ data }) => {
        if (data.result) {
          dispatch({
            type: EMAIL.OTP_EMAIL_REQUEST,
            data: Object.assign(data.data, { email, lang }),
          });
        } else {
          dispatch({
            type: EMAIL.OTP_EMAIL_REQUEST_FAILED,
            data: Object.assign(data.error, { email, lang }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EMAIL.OTP_EMAIL_REQUEST_FAILED,
          data: Object.assign(
            { code: "internal-error", message: error },
            { email, lang }
          ),
        });
      });
  };
};

export const OTPEmailVerify = (email, refCode, otp, memberBadgeCode, callback) => {
  const options_5y = { expires: 5 * 365 };
  const options_1d = { expires: 1 };
  const CookieNameOTP = '__otp';
  return async (dispatch) => {
    try {
      const signinResult = await axios({
        method: 'post',
        url: `${PAPI}/oauth2/OTPEmailVerify`,
        data: {
          email,
          refCode,
          otp,
          memberBadgeCode,
        },
      });

      if (!signinResult.data.result) {
        dispatch({
          type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
          data: Object.assign(signinResult.data.error, { email }),
        });
        return;
      }
      setCookie('__a', signinResult.data.data.__a, options_1d);
      setCookie('__s', signinResult.data.data.__s, options_5y);
      setCookie('__cid', signinResult.data.data.__cid, options_5y);

      const profile = await APIS.getUserProfile(signinResult);
      const profileData = profile.data;
      const phoneNumber = profileData.phoneNumber;

      const this_profile = profileData.data;
      const profile_member = {
        isLoading: false,
        auth: true,
        register: this_profile[0]?.is_registered
      };

      setNavigatorCredential({
        id: memberBadgeCode,
        name: phoneNumber,
        photo_uri: this_profile[0]?.photo_uri
      });

      Object.assign(window.ps, { uid: memberBadgeCode });

      removeCookie(CookieNameOTP);

      if (typeof callback === 'function') {
        callback(null, profileData);
      }

      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: profile_member,
        profile: this_profile,
        memberBadgeCode,
        phoneNumber
      });
    } catch (error) {
      ERROR(error);
      if (typeof callback === 'function') {
        callback(error);
      }
      dispatch({
        type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
        data: { error: "internal-error", message: error },
      });
    }
  };
};

export const OTPEmailVerifyAfterLogin = (email, refCode, otp) => {
  return (dispatch) => {
    dispatch({
      type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
      data: Object.assign({ code: false }, { email }),
    });
    axios({
      method: "post",
      url: `${PAPI}/oauth2/OTPEmailVerifyAfterLogin`,
      data: { email, refCode, otp },
    })
      .then(({ data }) => {
        if (data.result) {
          dispatch({
            type: EMAIL.OTP_EMAIL_VERIFY,
            data: Object.assign(data.data, { email }),
          });
        } else {
          dispatch({
            type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
            data: Object.assign(data.error, { email }),
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: EMAIL.OTP_EMAIL_VERIFY_FAILED,
          data: { error: "internal-error", message: error },
        });
      });
  };
};




function cooldown(dispatch, _lastLogin) {
  let cooldown = cooldown_time;
  let _time = parseInt(window.ps.getNowDate().getTime() / 1000);
  _lastLogin = parseInt(_lastLogin / 1000);

  cooldown = _lastLogin + cooldown > _time ? _lastLogin + cooldown - _time : 0;

  window.ps.process_cooldown_email && clearInterval(window.ps.process_cooldown_email);

  dispatch({ type: EMAIL.SET_RESEND_OTP_COOLDOWN, cooldown: cooldown });
  window.ps.process_cooldown_email = setInterval(() => {
    cooldown = cooldown - 1;
    dispatch({ type: EMAIL.SET_RESEND_OTP_COOLDOWN, cooldown: cooldown });
    if (cooldown < 0) clearInterval(window.ps.process_cooldown_email);
  }, 1000);
}

export const CheckEmailExist = (email) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/checkEmailExist`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { email },
    })
      .then(({ data }) => {
        let dataObject = data.data;
        if (dataObject.length > 0) {
          dispatch({
            type: EMAIL.EMAIL_EXIST,
            data: true,
          });
        } else {
          dispatch({
            type: EMAIL.EMAIL_EXIST,
            data: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: EMAIL.EMAIL_EXIST,
          data: null,
        });
      });
  };
};

export const setEmailExistNull = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.SET_EMAIL_EXIST_NULL });
  };
};

export const setTempMail = (tempEmail) => {
  return (dispatch) => {
    dispatch({ type: EMAIL.TEMP_MAIL, data: tempEmail });
  };
};

export const setResultFalse = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.SET_RESULT_FALSE });
  };
};

export const ResetError = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.ERROR_RESET });
  };
};

export const getConfigSettingEmail = (memberBadgeCode) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/getConfigSettingEmail`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { memberBadgeCode },
    })
      .then(({ data }) => {
        dispatch({
          type: EMAIL.SET_CONFIG_SETTING_EMAIL,
          summary: data.data.email_summary_noti,
          transaction: data.data.email_ts_noti,
        });
      })
      .catch((error) => {});
  };
};

export const updateEmailSummaryNoti = (value, memberBadgeCode) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/updateEmailSummaryNoti`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { value, memberBadgeCode },
    })
      .then(({ data }) => {
        dispatch({ type: EMAIL.UPDATE_EMAIL_SUMMARY, data: value });
      })
      .catch((error) => {});
  };
};

export const updateEmailTransactionNoti = (value, memberBadgeCode) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/az/email/updateEmailTransactionNoti`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { value, memberBadgeCode },
    })
      .then(({ data }) => {
        dispatch({ type: EMAIL.UPDATE_EMAIL_TRANSACTION, data: value });
      })
      .catch((error) => {});
  };
};

export const ResetCooldown = () => {
  return (dispatch) => {
    dispatch({ type: EMAIL.SET_RESEND_OTP_COOLDOWN, cooldown: 0 });
  };
}