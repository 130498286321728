import React, { useState, useEffect, useRef } from "react";
import "./VerifyEmail.css";
import OtpSection from "../../components/OtpSection/OtpSection";
import { useSelector, useDispatch } from "react-redux";
import {
  OTPEmailRequest,
  OTPEmailVerifyAfterLogin,
  ResetError,
} from "../../redux/actions/action_email";
import { getCookie } from "../../libs/cookie";
import {
  updateUserProfile,
  createUserProfile,
} from "../../redux/actions/action_profile";
import { nl2br } from "../../libs/nl2br";
import { toast } from "react-toastify";
import { ToastBar } from "../EditProfile/EditProfileForm.style";
import { Title2 } from "../../components/Font";

function VerifyEmail(props) {
  const langCurrent = getCookie("__lang") || "th";
  const { profile } = useSelector((state) => state.profile);
  const { dictionary } = useSelector((state) => state.language);
  const { line } = useSelector((state) => state.login);
  const { email, refCode, cooldown, tempMail, error, result, } = useSelector(
    (state) => state.email
  );
  
  const {
    refCodeFirst,
    checkOtp,
    back,
    TemProfile,
    backToRegister,
    subscribe,
    referer,
    isReferer,
  } = props;
  let { setIsRegister } = props;

  const [otp, setOtp] = useState("");
  const [resend, SetResend] = useState(false);
  const [emailUser, setEmailUser] = useState(tempMail || profile[0].email);
  const [refCodeNew, setRefCodeNew] = useState(refCodeFirst);
  const [errorCode, setErrorCode] = useState("");
  const [resendClick, setResendClick] = useState(false);

  const isRequest = useRef();

  const add_email_verified = {
    firstName: profile[0].first_name,
    lastName: profile[0].last_name,
    email: tempMail || profile[0].email,
    dateOfBirth: profile[0].date_of_birth,
    gender: profile[0].gender,
    noti_toggle: profile[0].cfg_block_noti,
    blockBirthday: profile[0].cfg_block_birthday,
    email_verified: true,
  };

  function toastShow(type, message) {
    toast[type](
      <ToastBar>
        <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
          {message}
        </Title2>
      </ToastBar>
    );
  }

  // for clean up isRequest
  useEffect(() => {
    return () => {
      isRequest.current = false;
    };
  }, []);

  useEffect(() => {
    setEmailUser(email);
  }, [email]);

  useEffect(() => {
    setRefCodeNew(refCode);
  }, [refCode]);

  useEffect(() => {
    if (result === true) {
      if (profile[0].country_code === "+66") {
        update_email_verified();
        checkOtp(true);
        toastShow("success", dictionary.verify_email_successfully);
      } else if (TemProfile) {
        create_user_profile();
        if (typeof setIsRegister === "function") {
          setIsRegister(true);
        }
      } else {
        update_email_verified();
        checkOtp(true);
        toastShow("success", dictionary.verify_email_successfully);
      }
    }

  }, [result, profile, TemProfile, dictionary]);

  useEffect(() => {
    if (error) {
      setErrorCode(error);
    }
  }, [error]);

  useEffect(() => {
    if (errorCode === "oauth2/otp-too-many-retry" && resendClick) {
      setErrorCode("");
      setResendClick(false);
    }
  }, [errorCode, resendClick]);

  const dispatch = useDispatch();

  let mail;
  if (profile[0].country_code === "+66") {
    mail = emailUser;
  } else if (TemProfile) {
    mail = TemProfile.email;
  } else {
    mail = emailUser;
  }

  const requestOTP = () => {
    dispatch(OTPEmailRequest(mail, langCurrent));
  };

  const verifyOTP_Thai = () => {
    dispatch(OTPEmailVerifyAfterLogin(emailUser, refCodeNew, otp));
  };

  const verifyOTP_Foreign = () => {
    dispatch(OTPEmailVerifyAfterLogin(TemProfile.email, refCodeNew, otp));
  };

  const verifyOTPAgain = () => {
    dispatch(OTPEmailVerifyAfterLogin(mail, refCodeNew, otp));
  };

  const update_email_verified = () => {
    dispatch(updateUserProfile(add_email_verified));
  };

  const create_user_profile = () => {
    // condition ป้องกันการ request ซ้ำ
    if (isRequest.current) return null;

    let userProfile = {
      firstName: TemProfile.firstName && TemProfile.firstName.trim(),
      lastName: TemProfile.lastName && TemProfile.lastName.trim(),
      dateOfBirth: TemProfile.dateOfBirth || "01-01-1907",
      gender: TemProfile.gender,
      email: TemProfile.email,
      email_verified: true,
    };

    isRequest.current = true; // set true เพื่อป้องกันการ request ซ้ำ
    dispatch(
      createUserProfile(userProfile, line, (err, data) => {
        if (typeof subscribe === "function") {
          let _data = {};
          if (referer && isReferer) Object.assign(_data, { referer: referer });
          subscribe(_data);
        }
      })
    );
  };

  function reqEmailAgain() {
    setOtp("");
    ResetError("");
    requestOTP();
    SetResend(true);
    setResendClick(true);
  }

  function verifyEmail() {
    if (resend === false) {
      if (profile[0].country_code === "+66") {
        verifyOTP_Thai();
      } else if (TemProfile) {
        verifyOTP_Foreign();
      } else {
        verifyOTP_Thai();
      }
    } else {
      verifyOTPAgain();
    }
  }

  function updateOtp(otp_code) {
    ResetError("");
    setOtp(otp_code);
  }

  function backToBeforeVerifyEmail() {
    back(false);
  }

  function backToRegisterPage() {
    ResetError("");
    if (
      document.location.href.indexOf("register") > -1 ||
      document.location.href.indexOf("send") > -1
    )
      backToRegister(false);
    else back(false);
  }

  return (
    <div>
      <div className="container-total">
        <div className="container-verify">
          <p>{dictionary.verification_sent}</p>
          <p>
            <b>{mail}</b>
          </p>
          {errorCode === "oauth2/otp-limit-exceeded" ? null : (
            <p>
              ({dictionary.verify_ref} - {refCodeNew})
            </p>
          )}
        </div>

        <div className="verification-code">
          {errorCode === "oauth2/otp-too-many-retry" ||
          errorCode === "oauth2/otp-limit-exceeded" ? null : (
            <OtpSection updateOtp={updateOtp} otp={otp} />
          )}
          <br />
          {errorCode ? (
            <div className="incorrect-otp">{nl2br(dictionary[errorCode])}</div>
          ) : null}
        </div>

        <div className="container-link">
          {errorCode === "oauth2/otp-limit-exceeded" ? null : (
            <button
              className="button-verify"
              onClick={verifyEmail}
              disabled={
                otp.length !== 5 || errorCode === "oauth2/otp-too-many-retry"
              }
            >
              {dictionary.verify_button}
            </button>
          )}
          <br /> <br />
          <div>
            {errorCode === "oauth2/otp-limit-exceeded" ? null : cooldown <=
              0 ? (
              <button className="link-text" onClick={reqEmailAgain}>
                {dictionary.no_email}
              </button>
            ) : (
              <div>
                <button className="link-text-disable" disabled={true}>
                  {dictionary.send_email_again} ({cooldown})
                </button>
              </div>
            )}
          </div>
          <br />
          {profile[0].country_code === "+66" ? (
            <div>
              <button className="link-text" onClick={backToBeforeVerifyEmail}>
                {dictionary.back}
              </button>
            </div>
          ) : (
            <button className="link-text" onClick={backToRegisterPage}>
              {dictionary.back}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
export default VerifyEmail;
